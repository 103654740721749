import React from "react";
import CareerDetailPage from "./CareerDetailPage";

const careerDetails = {
    title: "Restaurant Cashier",
    type: "Full Time",
    level: "Mid Level",

    description:
        "The Cashiering department, responsible for the billing process, is a critical part of any store. The quality of the team and the systems used to manage transactions are vital for the efficient functioning of the store.",

    qualifications: [
        "Excellent communication and interpersonal skills.",
        "Strong organizational skills and attention to detail..",
        "Ability to handle cash accurately and efficiently.",
        "Knowledge of the products offered by Angan Sweets.",
        "Basic computer skills for managing social media posts and handling billing systems.",
        "Previous experience in a similar role is an advantage but not required.",
    ],

    responsibilities: [
        "Ensure the counter and its shelves are kept clean and tidy at all times.",
        "Greet customers with “Good morning/afternoon/evening” to create a welcoming atmosphere.",
        "Maintain proper uniform standards with well-groomed hair.",
        "Manage expenses, dispatch, and branch transfers as instructed by outlet managers or the person in charge. Perform cash reconciliation at the end of each shift.",
        "Assist customers who are unsure about their orders by suggesting new products or popular items based on their preferences.",
        "Handle orders patiently and courteously, even if customers take time to decide.",
        "Be aware of item availability and maintain clear communication with sales personnel to ensure smooth order processing.",
        "Have a thorough understanding of the products available at Aangan Sweets, including sweets, namkeen, fast food, gift hampers, and dry fruits. Assist customers in identifying products they want to purchase.",
        "Coordinate with the factory before and after taking special orders to ensure proper fulfillment.",
        "Ensure the display counter is always properly managed and clean to attract customers.",
        "Answer calls with, “Namaste, Aangan Sweets (Branch Name),” and maintain a polite and friendly tone."
    ],
    preferredQualifications: [
        "Must Have Atleast 1 year experience in related fields ",
    ],
    noOfOpenings: 5
};

const RestaurantCashier = () => {
    return <CareerDetailPage careerDetails={careerDetails} />;
};

export default RestaurantCashier;
