import React from "react";
import CareerDetailPage from "./CareerDetailPage";

const careerDetails = {
    title: "Store Keeper",
    type: "Full Time",
    level: "Mid Level",
    //   education: [
    //     "Bachelor’s degree in graphic design, industrial design, or interior design, or equivalent experience",
    //     "Five to eight years of experience in graphic design",
    //   ],
    description:
        "We are seeking a detail-oriented and organized individual to join our team as a Store Keeper. The Store-Keeper will be responsible for overseeing the efficient receipt, storage, and dispatch of a wide range of goods in our establishment. The ideal candidate will possess strong organizational skills, attention to detail, and the ability to work effectively in a fast-paced environment.",
    qualifications: [
        "Proven experience in a similar role, preferably in a retail or warehouse environment",
        "Strong organizational and time management skills",
        "Excellent attention to detail and accuracy",
        "Proficiency in using inventory management software and MS Office applications",
        "Ability to work independently as well as part of a team",
        "Strong communication and interpersonal skills"
    ],
    responsibilities: [
        "Receive incoming goods and ensure they are of accurate quantity and quality",
        "Properly label, categorize, and store items in designated areas.",
        "Maintain inventory levels by conducting regular stock counts and reconciliations.",
        "Monitor stock movement and generate reports on inventory levels, ensuring adequate stock availability.",
        "Coordinate with purchasing and procurement teams to replenish stock as needed.",
        "Prepare goods for dispatch, ensuring accurate documentation and packaging.",
        "Conduct regular inspections of the storage facilities to ensure cleanliness, orderliness, and compliance with safety standards.",
        "Handle and resolve any discrepancies or issues related to stock and inventory.",
        "Utilize inventory management software and systems effectively to track stock movements and maintain accurate records.",
        "Assist in conducting periodic audits and implementing improvements to inventory management processes."
    ],
    preferredQualifications: [
        "Must Have Atleast 1 year experience in related fields ",
    ],
    noOfOpenings: 1
};

const StoreKeeper = () => {
    return <CareerDetailPage careerDetails={careerDetails} />;
};

export default StoreKeeper;
