import React from "react";
import CareerDetailPage from "./CareerDetailPage";

const careerDetails = {
    title: " Assistant Operations Manager",
    type: "Full Time",
    level: "Mid Level",
    //   education: [
    //     "Bachelor’s degree in graphic design, industrial design, or interior design, or equivalent experience",
    //     "Five to eight years of experience in graphic design",
    //   ],

    description:
        "We are looking for a detail-oriented and proactive Assistant Operations Manager to join our team. The Assistant Operation Manager will work closely with the Operations Manager to oversee daily operations and ensure the smooth functioning of our business. The ideal candidate will have strong leadership skills, a keen eye for detail, and a passion for driving operational excellence",
   
        qualifications: [
        "Bachelor's degree in Business Administration, Operations Management, or related field.",
        "Proven experience in a similar role, with a strong understanding of business operations and management principles.",
        "Excellent leadership, communication, and interpersonal skills, with the ability to motivate and inspire teams.",
        "Strong analytical and problem-solving abilities, with a focus on data-driven decision-making.",
        "Proficiency in Microsoft Office suite and operational management software.",
        "Ability to thrive in a fast-paced environment and manage multiple priorities effectively.",
        "Knowledge of industry best practices and trends in operations management."
    ],

    responsibilities: [
        "Assist the Operation Manager in planning, organizing, and directing the day-to-day operations of the company",
        "Develop and implement policies and procedures to help the company achieve its goals",
        "Periodic drafting, review, and update of SOP for different departments with HR and senior manager",
        "Plan, organize, and strategize for daily operations and routine",
        "Monitor and analyze operational processes to identify areas for improvement and implement solutions to increase efficiency and productivity",
        "Coordinate with various departments to ensure seamless communication and collaboration across the organization",
        "Supervise staff, including training, scheduling, and performance management, to ensure high levels of productivity and customer satisfaction",
        "Oversee inventory management, including ordering, receiving, and storing of materials and supplies, to meet production and customer demands",
        "Ensure compliance with company policies, procedures, and regulatory requirements, including health and safety standards",
        "Prepare regular reports on operational performance, including key metrics such as productivity, quality, and customer satisfaction, and provide recommendations for improvement",
        "Assist in the development and implementation of strategic plans and initiatives to support the company's growth and objectives",
        "Foster a positive and inclusive work environment that promotes teamwork, communication, and continuous learning"
    ],
    preferredQualifications: [
        "Must Have Atleast 2 year experience in related fields ",
    ],
    noOfOpenings: 1
};

const OperationsManager = () => {
    return <CareerDetailPage careerDetails={careerDetails} />;
};

export default OperationsManager;
