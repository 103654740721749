import React from "react";
import CareerDetailPage from "./CareerDetailPage";

const careerDetails = {
    title: "Factory Dispatch Officer",
    type: "Full Time",
    level: "Mid Level",
    //   education: [
    //     "Bachelor’s degree in graphic design, industrial design, or interior design, or equivalent experience",
    //     "Five to eight years of experience in graphic design",
    //   ],

    description:
        "We are seeking a highly organized and detail-oriented Factory Dispatch Officer to join our growing team! In this on-site role, you will be responsible for the efficient and timely dispatch of finished goods from our factory, ensuring all shipments meet customer requirements.",

    qualifications: [
        "Strong understanding of shipping and receiving procedures",
        "Excellent organizational and time management skills",
        "Proficient in MS Office Suite (Word, Excel, Outlook)",
        "Excellent written and verbal communication skills",
        "Ability to prioritize tasks and work effectively in a fast-paced environment",
        "Strong attention to detail and accuracy",
        "Ability to maintain a positive and professional demeanor"

    ],

    responsibilities: [
        "Receive and process production orders and shipping instructions.",
        "Verify order accuracy and ensure all necessary documentation is complete.",
        "Coordinate with production and warehouse teams to schedule product pick-ups and ensure on-time deliveries.",
        "Arrange for appropriate transportation based on shipment size, weight, and urgency.",
        "Communicate effectively with carriers and track shipments until delivery.",
        "Maintain accurate records of all dispatched goods, including quantities, destinations, and shipping details.",
        "Identify and resolve any logistical issues that may arise during the dispatch process.",
        "Assist with inventory management as needed",
        " Maintain a safe and clean work environment."
    ],
    preferredQualifications: [
        "Must Have Atleast 1 year experience in related fields ",
    ],
    noOfOpenings: 1
};

const FactoryDispatchOfficer = () => {
    return <CareerDetailPage careerDetails={careerDetails} />;
};

export default FactoryDispatchOfficer;
