import React from "react";
import CareerDetailPage from "./CareerDetailPage";

const careerDetails = {
    title: " Human Resource Officer",
    type: "Full Time",
    level: "Mid Level",
    //   education: [
    //     "Bachelor’s degree in graphic design, industrial design, or interior design, or equivalent experience",
    //     "Five to eight years of experience in graphic design",
    //   ],

    description:
        "The Human Resources Officer will be responsible for the overall management and implementation of Human Resources policies, procedures, guidelines, and tools",

    qualifications: [
        "Bachelor's Degree in a relevant field.",
        "Proficient in HRIS systems.",
        "Excellent communication and leadership skills.",
        "Strong technical and problem-solving abilities.",
        "Proficient in both spoken and written communication.",
        "Effective problem-solving skills to handle disputes, grievances, and staffing issues.",
        "Competent in using databases, spreadsheets, word processing, and - Microsoft Office packages."
    ],

    responsibilities: [
        "Leading HR functions, including workforce planning, recruitment, staff administration management, and staff development",
        "Managing the selection and hiring process.",
        "Ensuring timely and accurate documentation of monthly HR payroll processes."
    ],
    preferredQualifications: [
        "Must Have Atleast 2 year experience in related fields ",
    ],
    noOfOpenings: 1
};

const HumanResourceOfficer = () => {
    return <CareerDetailPage careerDetails={careerDetails} />;
};

export default HumanResourceOfficer;
