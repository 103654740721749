import React, { useEffect } from "react";
import PageHeader from "./../common/PageHeader";
import adminstrativeAssistant from "../../assets/images/career/adminstrative-assistant.jpeg";
import FactoryDispatch from "../../assets/images/career/factory-dispatch.jpeg";
import storeKeeper from "../../assets/images/career/storekeeper.jpg";
import { Link } from "react-router-dom";
import HR from "../../assets/images/career/hr.jpg";


const openings = [
  {
    title: "Human Resource Officer",
    shortDescription:
      "The Human Resources Officer will be responsible for the overall management and implementation of Human Resources policies, procedures, guidelines, and tools.",
    link: "/career/human-resource-officer",
    logo: HR,
  },
  {
    title: "Restaurant Cashier",
    shortDescription:
      "The Cashiering department, responsible for the billing process, is a critical part of any store. The quality of the team and the systems used to manage transactions are vital for the efficient functioning of the store.",
    link: "/career/restaurant-cashier",
    logo: adminstrativeAssistant,
  },
  {
    title: "Store Keeper",
    shortDescription:
      "The Store-Keeper will be responsible for overseeing the efficient receipt, storage, and dispatch of a wide range of goods in our establishment. The ideal candidate will possess strong organizational skills, attention to detail, and the ability to work effectively in a fast-paced environment.",
    link: "/career/store-keeper",
    logo: storeKeeper,
  },
  {
    title: "Factory Dispatch Officer",
    shortDescription:
      "The Cashiering department, responsible for the billing process, is a critical part of any store. The quality of the team and the sy",
    link: "/career/factory-dispatch",
    logo: FactoryDispatch,
  },

  {
    title: "Assistant Operations Manager",
    shortDescription:
      "The Assistant Operation Manager will work closely with the Operations Manager to oversee daily operations and ensure the smooth functioning of our business.",
    link: "/career/operation-manager",
    logo: adminstrativeAssistant,
  },

];

const CareerPage = () => {
  useEffect(() => {
    document.title = "Aakash Group | Career";
  }, []);

  return (
    //  <div className="title">
    //   <div className="heading">Current Openings</div>
    //   <span>Look at the job posting(s) below and apply!</span>
    // </div>
    <div className="career-page page">
      <PageHeader
        heading="Careers"
        description="Since we have been growing fast lately, we are looking for members to join our team!!"
      />
      <div className="container">
        {/* <div className="title">
          <div className="heading">No Current Openings</div>
          <span>Please Ssddtay Tuned!</span>
        </div> */}
        <div className="title">
          <div className="heading">Current Openings</div>
        </div>
        <ul className="openings">
          {openings.map((opening, index) => (
            <li key={index} className="opening">
              <div className="wrapper">
                <div className="image">
                  <img src={opening.logo} alt="" />
                </div>
                <div className="details">
                  <div className="title">{opening.title}</div>
                  <div className="details-bot">{opening.shortDescription}</div>
                </div>
                <div className="apply-wrap">
                  <Link to={opening.link}>
                    <button>Apply</button>
                  </Link>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CareerPage;
